//import { Box, Stack, Typography } from "@mui/material";
import React from "react";

/* --color-neutral-muted: rgba(175,184,193,0.2);
  --color-neutral-subtle: rgba(234,238,242,0.5); */
  const blockWrapAlignFullStyle = {
    position: "relative",
    width: "calc(100vw - 17px)",
    maxWidth: "100vw !important",
    left: "50%",
    transform: "translateX(-50%)",
    padding: "0 30px"
  };
const Articles = () => {
  return (
    <>
      <article
        itemScope=""
        itemType="https://schema.org/CreativeWork"
        style={{ display: "block" }}
      >
        <div
          itemProp="text"
          style={{ maxWidth: "650px", position: "relative", margin: "auto" }}
        >
          <div style={blockWrapAlignFullStyle}>
            <div style={{ padding: "56px 0 53px" }}>
              <div
                style={{
                  position: "relative",
                  maxWidth: "1110px",
                  margin: "auto",
                }}
              >
                <h1
                  style={{
                    margin: "0 0 20px",
                    fontSize: "48px",
                    lineHeight: "66px",
                    letterSpacing: "-1px",
                  }}
                >
                  Articles
                </h1>
                <div style={{ maxWidth: "825px" }}>
                  <p
                    style={{
                      margin: "0 0 1em",
                      fontSize: "28px",
                      lineHeight: "37px",
                    }}
                  >
                    Writing is a highly underutilized tool for innovators. I
                    find that writing not only helps me to formulate my thoughts
                    with clarity in order to communicate them with others but
                    also serves as a tool of self reflection which often
                    triggers unexpected solutions to problems I have been
                    pondering. Below is a collection of my articles and papers
                    that I authored on a variety of topics ranging from sci-fi
                    short stories to interesting math problems.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <style>
            {`
              .container.padding {
                position: relative;
                max-width: 1170px;
                padding: 0 30px;
                margin: auto;
              }
              .row {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 81px;
              }
              .block.book .row {
                margin-bottom: 0;
              }
              .block.book.orange-background .row {
                align-items: center;
              }
              .block.book .cover {
                position: relative;
                max-width: 350px;
                margin-right: 40px;
              }
              .block.book.orange-background .cover {
                align-self: flex-start;
              }
              .block.book .cover::before {
                position: absolute;
                display: block;
                content: '';
                box-shadow: 0px 20px 45px rgba(17, 51, 75, 0.15);
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
              }
              .row .content {
                max-width: 835px;
              }
              .block.book .content {
                position: relative;
                padding-top: 57px;
              }
              .block.book.orange-background .content {
                padding-top: 0;
              }
              .row .content h2 {
                font-size: 20px;
                font-weight: normal;
                line-height: normal;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin: 0 0 23px;
              }
              .block.book .content h2 {
                margin-bottom: 7px;
                color: #f26111;
              }
              .block.book.orange-background .content h2 {
                margin-bottom: 3px;
              }
              .block.book .content h3 {
                margin: 0 0 38px;
                font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
                font-size: 78px;
                font-weight: bold;
                line-height: 82px;
                color: #001828;
                text-shadow: 2px 2px #fff, 2px -2px #fff, -2px 2px #fff, -2px -2px #fff, 3px 3px #fff, 3px -3px #fff, -3px 3px #fff, -3px -3px #fff;
                text-transform: none;
                letter-spacing: -1.5px;
              }
              .block.book.orange-background .content h3 {
                margin-bottom: 22px;
                font-size: 48px;
                line-height: 66px;
                text-shadow: none;
                letter-spacing: -1px;
              }
              .block a:not(.underline):not(.btn) {
                font-weight: inherit;
                color: inherit;
                border-bottom: none;
              }
              .row .content p {
                margin: 0 0 36px;
                font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
                font-size: 28px;
                line-height: 37px;
              }
              .block.book .content p {
                margin: 0 0 37px;
                font-size: 20px;
                line-height: 29px;
              }
              .block.book.orange-background .content p {
                margin-bottom: 30px;
                font-size: 20px;
                line-height: 29px;
              }
              .block.product .links {
                display: flex;
                flex-wrap: wrap;
                margin: -10px -25px;
              }
              .block.product .links .item {
                margin: 10px 25px;
              }
            `}
          </style>
          <div className={"blockWrap"} style={blockWrapAlignFullStyle}>
            <div
              className={"block book orange-background"}
              style={{
                lineHeight: 1.15,
                WebkitTextSizeAdjust: "100%",
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                color: "#001828",
                WebkitFontSmoothing: "antialiased",
                boxSizing: "border-box",
                padding: "96px 0",
                marginBottom: "30px",
                backgroundColor: "#fef7f3",
              }}
            >
              <div className={"container padding"}>
                <div className={"row"}>
                  <div className={"content"}>
                    <h3>
                      <a href={`${process.env.PUBLIC_URL}/assets/articles/tachyon.pdf`} target="_blank" rel="noreferrer noopener">
                        <span>Tachyon - Architecture</span>
                        <h2>Programming Language</h2>
                      </a>
                    </h3>
                    <p>
                      This is a paper on the architecture of Tachyon, a
                      lightweight dynamic programming language I designed with
                      two primary goals in mind: developer's productivity and
                      performance. Tachyon provides an efficient environment
                      that delivers superior performance. It's transpiled to
                      modern C++ using tagged unions to handle dynamic typing,
                      and can seamlessly interact with C++ code.
                    </p>
                    <div className={"links"}>
                      <div className={"item button"}>
                        <a
                          className={"btn btn-primary"}
                          href={`${process.env.PUBLIC_URL}/assets/articles/tachyon.pdf`} target="_blank" rel="noreferrer noopener">
                          <span>Learn More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"blockWrap"} style={blockWrapAlignFullStyle}>
            <div
              className={"block book orange-background"}
              style={{
                lineHeight: 1.15,
                WebkitTextSizeAdjust: "100%",
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                color: "#001828",
                WebkitFontSmoothing: "antialiased",
                boxSizing: "border-box",
                padding: "96px 0",
                marginBottom: "30px",
                backgroundColor: "#fef7f3",
              }}
            >
              <div className={"container padding"}>
                <div className={"row"}>
                  <div className={"content"}>
                    <h3>
                      <a
                        href="//teenink.com/fiction/sci_fi_fantasy/article/1199872/Eternal-Dawn-On-New-Earth"
                        target="_blank" rel="noreferrer noopener"
                      >
                        <span>Eternal Dawn on New Earth</span>
                        <h2>SciFi Story</h2>
                      </a>
                    </h3>
                    <p>
                      This is a scifi story authored by me on the experience of
                      a family adapting to their new home in the exoplanet
                      Gliese-667Cc. This short story was triggered by my
                      interest in exoplanets and my desire to address the
                      potential challenges we would have to face to make them
                      more habitable. It is an intro for a planned series.
                    </p>
                    <div className={"links"}>
                      <div className={"item button"}>
                        <a
                          className={"btn btn-primary"}
                          href="http://teenink.com/fiction/sci_fi_fantasy/article/1199872/Eternal-Dawn-On-New-Earth"
                          target="_blank" rel="noreferrer noopener"
                        >
                          <span>Learn More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"blockWrap"} style={blockWrapAlignFullStyle}>
            <div
              className={"block book orange-background"}
              style={{
                lineHeight: 1.15,
                WebkitTextSizeAdjust: "100%",
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                color: "#001828",
                WebkitFontSmoothing: "antialiased",
                boxSizing: "border-box",
                padding: "96px 0",
                marginBottom: "30px",
                backgroundColor: "#fef7f3",
              }}
            >
              <div className={"container padding"}>
                <div className={"row"}>
                  <div className={"content"}>
                    <h3>
                      <a
                        href={`${process.env.PUBLIC_URL}/assets/articles/half-exponential-function.pdf`} target="_blank" rel="noreferrer noopener">
                        <span>
                          Numerical approximation of the half-exponential
                          function
                        </span>
                        <h2>Math</h2>
                      </a>
                    </h3>
                    <p>
                      Mathematical function iterates play an important role in
                      many domains such as computer science, fractals &amp;
                      dynamic systems. An iterated function f: X X that is, a
                      function from some set X to itself is obtained by
                      composing another function, with itself a certain number
                      of times. In this paper, I study the reverse process of
                      finding a function f that, if composed with itself,
                      results in an exponential function.
                    </p>
                    <div className={"links"}>
                      <div className={"item button"}>
                        <a
                          className={"btn btn-primary"}
                          href={`${process.env.PUBLIC_URL}/assets/articles/half-exponential-function.pdf`} target="_blank" rel="noreferrer noopener">
                          <span>Learn More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"blockWrap"} style={blockWrapAlignFullStyle}>
            <div
              className={"block book orange-background"}
              style={{
                lineHeight: 1.15,
                WebkitTextSizeAdjust: "100%",
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                color: "#001828",
                WebkitFontSmoothing: "antialiased",
                boxSizing: "border-box",
                padding: "96px 0",
                marginBottom: "30px",
                backgroundColor: "#fef7f3",
              }}
            >
              <div className={"container padding"}>
                <div className={"row"}>
                  <div className={"content"}>
                    <h3>
                      <a
                        href={`${process.env.PUBLIC_URL}/assets/articles/brain-gan.pdf`} target="_blank" rel="noreferrer noopener">
                        <span>BrainGAN</span>
                        <h2>AI</h2>
                      </a>
                    </h3>
                    <p>
                      Building effective AI models for early detection of
                      Alzheimer’s from medical images requires a high quality,
                      diverse datasets. BrainGAN addresses the lack of such
                      images by using AI to synthesize PET brain images from
                      magnetic resonance imaging (MRI) data. It uses a specific
                      kind of Generative Adversarial Network model to generate
                      MRI data from PET images.
                    </p>
                    <div className={"links"}>
                      <div className={"item button"}>
                        <a
                          className={"btn btn-primary"}
                          href={`${process.env.PUBLIC_URL}/assets/articles/brain-gan.pdf`} target="_blank" rel="noreferrer noopener">
                          <span>Learn More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"blockWrap"} style={blockWrapAlignFullStyle}>
            <div
              className={"block book orange-background"}
              style={{
                lineHeight: 1.15,
                WebkitTextSizeAdjust: "100%",
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                color: "#001828",
                WebkitFontSmoothing: "antialiased",
                boxSizing: "border-box",
                padding: "96px 0",
                marginBottom: "30px",
                backgroundColor: "#fef7f3",
              }}
            >
              <div className={"container padding"}>
                <div className={"row"}>
                  <div className={"content"}>
                    <h3>
                      <a
                        href={`${process.env.PUBLIC_URL}/assets/articles/auto-diff.pdf`} target="_blank" rel="noreferrer noopener">
                        <span>Auto diff with dual numbers</span>
                        <h2>Math</h2>
                      </a>
                    </h3>
                    <p>
                      This is a paper on how dual numbers can be used to perform
                      automatic differentiation. It enables evaluating the
                      partial derivatives of a function specified by a software
                      program. It is a powerful tool that can be used to compute
                      derivatives of functions that are too complex to be
                      differentiated manually.
                    </p>
                    <div className={"links"}>
                      <div className={"item button"}>
                        <a
                          className={"btn btn-primary"}
                          href={`${process.env.PUBLIC_URL}/assets/articles/auto-diff.pdf`} target="_blank" rel="noreferrer noopener">
                          <span>Learn More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default Articles;
