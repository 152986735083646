//import { Box, Stack, Typography } from "@mui/material";
import React from "react";

/* --color-neutral-muted: rgba(175,184,193,0.2);
  --color-neutral-subtle: rgba(234,238,242,0.5); */
  const blockWrapAlignFullStyle = {
    position: "relative",
    width: "calc(100vw - 17px)",
    maxWidth: "100vw !important",
    left: "50%",
    transform: "translateX(-50%)",
    padding: "0 30px"
  };
const Projects = () => {
  return (
    <>
      <article
        itemScope=""
        itemType="https://schema.org/CreativeWork"
        style={{ display: "block" }}
      >
        <div
          itemProp="text"
          style={{ maxWidth: "650px", position: "relative", margin: "auto" }}
        >
          <div style={blockWrapAlignFullStyle}>
            <div style={{ padding: "56px 0 53px" }}>
              <div
                style={{
                  position: "relative",
                  maxWidth: "1110px",
                  margin: "auto",
                }}
              >
                <h1
                  style={{
                    margin: "0 0 20px",
                    fontSize: "48px",
                    lineHeight: "66px",
                    letterSpacing: "-1px",
                  }}
                >
                  Projects
                </h1>
                <div style={{ maxWidth: "825px" }}>
                  <p
                    style={{
                      margin: "0 0 1em",
                      fontSize: "28px",
                      lineHeight: "37px",
                    }}
                  >
                    Pursuing my passions has motivated me to create several
                    projects outside the classroom. My interests span multiple
                    domains such as googology, number theory, astronomy and
                    designing programming languages. I leverage common &amp;
                    recurring patterns in those domains to build impactful
                    applications and toolkits.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <style>
            {`
              .container.padding {
                position: relative;
                max-width: 1170px;
                padding: 0 30px;
                margin: auto;
              }
              .row {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 81px;
              }
              .block.book .row {
                margin-bottom: 0;
              }
              .block.book.orange-background .row {
                align-items: center;
              }
              .block.book .cover {
                position: relative;
                max-width: 350px;
                margin-right: 40px;
              }
              .block.book.orange-background .cover {
                align-self: flex-start;
              }
              .block.book .cover::before {
                position: absolute;
                display: block;
                content: '';
                box-shadow: 0px 20px 45px rgba(17, 51, 75, 0.15);
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
              }
              .row .content {
                max-width: 635px;
              }
              .block.book .content {
                position: relative;
                padding-top: 57px;
              }
              .block.book.orange-background .content {
                padding-top: 0;
              }
              .row .content h2 {
                font-size: 20px;
                font-weight: normal;
                line-height: normal;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin: 0 0 23px;
              }
              .block.book .content h2 {
                margin-bottom: 7px;
                color: #f26111;
              }
              .block.book.orange-background .content h2 {
                margin-bottom: 3px;
              }
              .block.book .content h3 {
                margin: 0 0 38px;
                font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
                font-size: 78px;
                font-weight: bold;
                line-height: 82px;
                color: #001828;
                text-shadow: 2px 2px #fff, 2px -2px #fff, -2px 2px #fff, -2px -2px #fff, 3px 3px #fff, 3px -3px #fff, -3px 3px #fff, -3px -3px #fff;
                text-transform: none;
                letter-spacing: -1.5px;
              }
              .block.book.orange-background .content h3 {
                margin-bottom: 22px;
                font-size: 48px;
                line-height: 66px;
                text-shadow: none;
                letter-spacing: -1px;
              }
              .block a:not(.underline):not(.btn) {
                font-weight: inherit;
                color: inherit;
                border-bottom: none;
              }
              .block img {
                display: block;
                max-width: 100%;
                height: auto;
                margin: auto;
              }
              .block.book .cover img {
                position: relative;
                z-index: 3;
              }
              .block.book .cover > a img:hover, .block.book .cover > a img:focus {
                box-shadow: 0px 20px 45px rgba(17, 51, 75, 0.25);
                transform: translateY(-4px);
              }
              .row .content p {
                margin: 0 0 36px;
                font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
                font-size: 28px;
                line-height: 37px;
              }
              .block.book .content p {
                margin: 0 0 37px;
                font-size: 20px;
                line-height: 29px;
              }
              .block.book.orange-background .content p {
                margin-bottom: 30px;
                font-size: 20px;
                line-height: 29px;
              }
              .block.product .links {
                display: flex;
                flex-wrap: wrap;
                margin: -10px -25px;
              }
              .block.product .links .item {
                margin: 10px 25px;
              }
            `}
          </style>
          <div className={"blockWrap"} style={blockWrapAlignFullStyle}>
            <div
              className={"block book orange-background"}
              style={{
                lineHeight: 1.15,
                WebkitTextSizeAdjust: "100%",
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                color: "#001828",
                WebkitFontSmoothing: "antialiased",
                boxSizing: "border-box",
                padding: "96px 0",
                marginBottom: "30px",
                backgroundColor: "#fef7f3",
              }}
            >
              <div className={"container padding"}>
                <div className={"row"}>
                  <div className={"cover"}>
                    <a href="//nirvanaself.com/coraliris/" target="">
                      <span>
                        <img
                          width="600"
                          height="838"
                          src={`${process.env.PUBLIC_URL}/assets/images/projects/coral-iris.jpg`}
                          className="attachment-full size-full"
                          alt=""
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </span>
                    </a>
                  </div>
                  <div className={"content"}>
                    <h3>
                      <a href="//nirvanaself.com/coraliris/" target="">
                        <span>Coral Iris</span>
                      </a>
                    </h3>
                    <h2>AI powered coral reef monitoring platform</h2>
                    <p>
                      Coral Iris leverages the power of AI to revolutionize
                      coral reef monitoring. Its intelligent, predictive model
                      analyzes underwater coral imagery, enabling scientists to
                      proactively identify and address coral health concerns.
                      Furthermore, Coral Iris fosters a collaborative framework
                      for marine biologists, citizen scientists, and government
                      agencies. This synergy empowers stakeholders to develop
                      data-driven intervention strategies, playing a pivotal
                      role in safeguarding coral reefs. Coral Iris is still in
                      development.
                    </p>
                    <div className={"links"}>
                      <div className={"item button"}>
                        <a
                          className={"btn btn-primary"}
                          href="//nirvanaself.com/coraliris/"
                          target=""
                        >
                          <span>Learn More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"blockWrap"} style={blockWrapAlignFullStyle}>
            <div
              className={"block book orange-background"}
              style={{
                lineHeight: 1.15,
                WebkitTextSizeAdjust: "100%",
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                color: "#001828",
                WebkitFontSmoothing: "antialiased",
                boxSizing: "border-box",
                padding: "96px 0",
                marginBottom: "30px",
                backgroundColor: "#fef7f3",
              }}
            >
              <div className={"container padding"}>
                <div className={"row"}>
                  <div className={"cover"}>
                    <a href="//tachyonlang.com/" target="">
                      <span>
                        <img
                          width="600"
                          height="838"
                          src={`${process.env.PUBLIC_URL}/assets/images/projects/tachyon.png`}
                          className="attachment-full size-full"
                          alt=""
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </span>
                    </a>
                  </div>
                  <div className={"content"}>
                    <h3>
                      <a href="//tachyonlang.com/" target="">
                        <span>Tachyon</span>
                      </a>
                    </h3>
                    <h2>
                      A fast, dynamic, object-oriented programming language
                    </h2>
                    <p>
                      Tachyon is a lightweight dynamic programming language
                      meticulously crafted with two primary goals in mind:
                      developer's productivity and performance. With a
                      minimalist and elegant programming paradigm, Tachyon
                      ensures that application developers can quickly grasp the
                      language with a low learning curve. It's specifically
                      designed for high-performance applications in domains like
                      machine learning, numerical computing, and mathematical
                      simulation.
                    </p>
                    <div className={"links"}>
                      <div className={"item button"}>
                        <a
                          className={"btn btn-primary"}
                          href="//tachyonlang.com/"
                          target=""
                        >
                          <span>Learn More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"blockWrap"} style={blockWrapAlignFullStyle}>
            <div
              className={"block book orange-background"}
              style={{
                lineHeight: 1.15,
                WebkitTextSizeAdjust: "100%",
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                color: "#001828",
                WebkitFontSmoothing: "antialiased",
                boxSizing: "border-box",
                padding: "96px 0",
                marginBottom: "30px",
                backgroundColor: "#fef7f3",
              }}
            >
              <div className={"container padding"}>
                <div className={"row"}>
                  <div className={"cover"}>
                    <a
                      href="//youtu.be/bjuC3mTk_08?si=1GAcwxr9aRUKz7nP"
                      target=""
                    >
                      <span>
                        <img
                          width="600"
                          height="838"
                          src={`${process.env.PUBLIC_URL}/assets/images/projects/surya.png`}
                          className="attachment-full size-full"
                          alt=""
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </span>
                    </a>
                  </div>
                  <div className={"content"}>
                    <h3>
                      <a
                        href="//youtu.be/bjuC3mTk_08?si=1GAcwxr9aRUKz7nP"
                        target=""
                      >
                        <span>Surya</span>
                      </a>
                    </h3>
                    <h2>
                      iOS app currently in development for viewing exoplanets
                    </h2>
                    <p>
                      I am currently developing an iOS app (named Surya) which
                      will enable users to interactively browse exoplanets and
                      the corresponding star systems in 3D. It would also help a
                      user to gather specific information about the type of the
                      star system, mass, radius, size of semi-major axis,
                      eccentricity etc. I have compiled the database of
                      exoplanets from the Extrasolar Planets Encyclopaedia
                      available at http://exoplanet.eu/. I have used the
                      SceneKit API from iOS to render the planetary bodies in
                      interactive 3D.
                    </p>
                    <div className={"links"}>
                      <div className={"item button"}>
                        <a
                          className={"btn btn-primary"}
                          href="//youtu.be/bjuC3mTk_08?si=1GAcwxr9aRUKz7nP"
                          target=""
                        >
                          <span>Learn More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"blockWrap"} style={blockWrapAlignFullStyle}>
            <div
              className={"block book orange-background"}
              style={{
                lineHeight: 1.15,
                WebkitTextSizeAdjust: "100%",
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                color: "#001828",
                WebkitFontSmoothing: "antialiased",
                boxSizing: "border-box",
                padding: "96px 0",
                marginBottom: "30px",
                backgroundColor: "#fef7f3",
              }}
            >
              <div className={"container padding"}>
                <div className={"row"}>
                  <div className={"cover"}>
                    <a href="//youtu.be/5Nlznm-Ct5A" target="">
                      <span>
                        <img
                          width="600"
                          height="838"
                          src={`${process.env.PUBLIC_URL}/assets/images/projects/brain-gan.png`}
                          className="attachment-full size-full"
                          alt=""
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </span>
                    </a>
                  </div>
                  <div className={"content"}>
                    <h3>
                      <a href="//youtu.be/5Nlznm-Ct5A" target="">
                        <span>BrainGAN</span>
                      </a>
                    </h3>
                    <h2>Generative model to synthesize PET images</h2>
                    <p>
                      Lack of high quality medical images is a huge obstacle in
                      building effective AI models to predict Alzheimer’s
                      disease.This prototype project seeks to address this
                      barrier by using AI to synthesize PET brain images from
                      magnetic resonance imaging (MRI) data. I use a specific
                      kind of Generative Adversarial Network model (Pix2Pix) to
                      generate MRI data from PET images. These synthetic images
                      can be used in combination with real PET images to train
                      an AI classifier for early detection of Alzheimer’s
                      disease.
                    </p>
                    <div className={"links"}>
                      <div className={"item button"}>
                        <a
                          className={"btn btn-primary"}
                          href="//youtu.be/5Nlznm-Ct5A"
                          target=""
                        >
                          <span>Learn More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"blockWrap"} style={blockWrapAlignFullStyle}>
            <div
              className={"block book orange-background"}
              style={{
                lineHeight: 1.15,
                WebkitTextSizeAdjust: "100%",
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                color: "#001828",
                WebkitFontSmoothing: "antialiased",
                boxSizing: "border-box",
                padding: "96px 0",
                marginBottom: "30px",
                backgroundColor: "#fef7f3",
              }}
            >
              <div className={"container padding"}>
                <div className={"row"}>
                  <div className={"cover"}>
                    <a href="//github.com/nirvanasupermind/termite" target="">
                      <span>
                        <img
                          width="600"
                          height="838"
                          src={`${process.env.PUBLIC_URL}/assets/images/projects/termite.png`}
                          className="attachment-full size-full"
                          alt=""
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </span>
                    </a>
                  </div>
                  <div className={"content"}>
                    <h3>
                      <a href="//github.com/nirvanasupermind/termite" target="">
                        <span>Termite</span>
                      </a>
                    </h3>
                    <h2>C++ based ternary virtual machine</h2>
                    <p>
                      Termite is a virtual machine for a ternary-based RISC CPU
                      architecture. Ternary logic is an alternative to binary
                      logic used in every modern processor. As a base-3
                      numerical system can represent numbers using fewer digits
                      than in base-2, ternary logic circuits are theorized to be
                      more compact than equivalent binary circuits. This
                      increase in density could lead to a gain in performance
                      measured in transistor cost, maximal frequency and energy
                      consumption. In addition Termite has important
                      applications in cryptography.
                    </p>
                    <div className={"links"}>
                      <div className={"item button"}>
                        <a
                          className={"btn btn-primary"}
                          href="//github.com/nirvanasupermind/termite"
                          target=""
                        >
                          <span>Learn More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"blockWrap"} style={blockWrapAlignFullStyle}>
            <div
              className={"block book orange-background"}
              style={{
                lineHeight: 1.15,
                WebkitTextSizeAdjust: "100%",
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                color: "#001828",
                WebkitFontSmoothing: "antialiased",
                boxSizing: "border-box",
                padding: "96px 0",
                marginBottom: "30px",
                backgroundColor: "#fef7f3",
              }}
            >
              <div className={"container padding"}>
                <div className={"row"}>
                  <div className={"cover"}>
                    <a
                      href="//github.com/nirvanasupermind/huge-number/"
                      target=""
                    >
                      <span>
                        <img
                          width="600"
                          height="838"
                          src={`${process.env.PUBLIC_URL}/assets/images/projects/huge-num.png`}
                          className="attachment-full size-full"
                          alt=""
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </span>
                    </a>
                  </div>
                  <div className={"content"}>
                    <h3>
                      <a
                        href="//github.com/nirvanasupermind/huge-number/"
                        target=""
                      >
                        <span>Huge Number</span>
                      </a>
                    </h3>
                    <h2>
                      Javascript toolkit for manipulating very large numbers
                    </h2>
                    <p>
                      Googology is the mathematical study of large numbers and
                      fast-growing functions, as well as their properties and
                      nomenclature. The built-in data types available in the
                      Javascript programming language does not provide abilities
                      to construct &amp; manipulate large numbers. Huge-Number
                      is a Javascript library designed for that purpose.
                    </p>
                    <div className={"links"}>
                      <div className={"item button"}>
                        <a
                          className={"btn btn-primary"}
                          href="//github.com/nirvanasupermind/huge-number/"
                          target=""
                        >
                          <span>Learn More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default Projects;
