//import { Box, Stack, Typography } from "@mui/material";
import React from "react";

/* --color-neutral-muted: rgba(175,184,193,0.2);
  --color-neutral-subtle: rgba(234,238,242,0.5); */

const DiffModel = () => {
  return (
    <>
      <style>
        {`
            body {
                background: #eef1f0 url(${process.env.PUBLIC_URL}/assets/images/books/diffmodel-background.jpg) repeat center 79px;
                font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
            }
            body {
                line-height: 1;
            }
            #page {
                width: 1000px;
                background: #fff;
                margin: 50px auto;
                -webkit-box-shadow: 0px 0px 5px rgba(0,0,0,.3);
                -moz-box-shadow: 0px 0px 5px rgba(0,0,0,.3);
                box-shadow: 0px 0px 5px rgba(0,0,0,.3);
                position: relative;
            }
            html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
                margin: 0;
                padding: 0;
                border: 0;
                outline: 0;
                font-size: 100%;
                vertical-align: baseline;
                background: transparent;
            }
        `}
      </style>
      <div id="page">
        <style>
          {`#page .heading {
                text-align: center;
                padding-top: 100px;
            }

            h1, h2 {
                margin: 10px 0;
                font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
                font-size: 42px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: #272727;
            }
          
          #page .content_lead {
            background: url(${process.env.PUBLIC_URL}/assets/images/books/diffmodel-cover-1.png) no-repeat 540px 65px;
            background-size: 365px 510px;
            padding: 40px 500px 60px 120px;
            border-bottom: 1px solid #eee;
            margin-bottom: 50px;
        }
        p {
            font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
            font-size: 18px;
            font-style: normal;
            line-height: 1.5em;
            margin: 20px 0;
        }
        `}
        </style>
        <h1 className="heading">Understanding Diffusion Models</h1>

        <div className="content content_lead">
          <p>
            Diffusion models have emerged as the new state-of-the-art family of
            generative models in the field of deep learning. It has been shown
            that they can generate more realistic and diverse images than
            Generative Adversarial Networks (GAN), challenging the long-standing
            dominance of GANs in image synthesis. Diffusion models can be used
            in a variety of domains, ranging from computer vision, natural
            language processing and multi-modal modeling.
          </p>

          <p>
            This book is for anyone who wants to understand this family of
            fascinating models, both from a mathematical perspective as well as
            a practical perspective of implementing them using Python. The
            book's conversational style, extensive illustrations, mathematical
            explanation, and real-world examples will explain the key concepts
            of diffusion models.
          </p>

          <p>
            The book will begin by how diffusion models were inspired by
            nonequilibrium thermodynamics in Physics, which governs phenomena
            like the spread of fluid. It will then introduce the basic idea of
            diffusion models: they work by starting with a noisy image or text
            and then gradually removing the noise, one step at a time. The model
            learns to reverse this process, so that it can generate new images
            or text that are similar to the training data.
          </p>

          <p>
            The book will then discuss the different architectures of diffusion
            models, including denoising diffusion probabilistic models,
            score-based generative models, and stochastic differential
            equations. It will also cover the different techniques that can be
            used to improve the performance of diffusion models, like
            conditioning on external data and using attention mechanisms.
          </p>

          <p>
            Finally, the book will address the applications of diffusion models
            to a variety of tasks, including image synthesis, text generation,
            text-to-image generation and video synthesis. It also discusses the
            challenges that remain in the development of diffusion models and
            the future directions of this research.{" "}
          </p>

          <p>
            I will provide functioning and well-documented Python code in github
            that would illustrate how to build models based on deep learning
            networks based on multiple architectures of diffusion models.
          </p>
        </div>
      </div>
    </>
  );
};

export default DiffModel;
