import {
  Outlet,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import {Stack } from "@mui/material";
import TabbedNavigation from "./pages/TabbedNavigation/TabbedNavigation";
import Home from "./pages/Home/Home";
import Books from "./pages/Books/Books";
import About from "./pages/About/About";
import Projects from "./pages/Projects/Projects";
import Articles from "./pages/Articles/Articles";
import DiffModel from "./pages/Books/DiffModel";
import CoralIris from "./pages/Projects/CoralIris";


function App() {
  return (
    <Router>
      <div>
        <Stack
          direction="column"
          sx={{
            background: "#fff",
            width: "100%",
            padding: '40px 30px 30px 30px',
            minHeight: "calc(100vh - 89px)",
          }}
        >
          <TabbedNavigation />
          <Outlet />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/books" element={<Books />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/about" element={<About />} />
            <Route path="/diffmodel" element={<DiffModel />} />
            <Route path="/coraliris" element={<CoralIris />} />
          </Routes>
        </Stack>
      </div>
    </Router>
  );
}

export default App;
