import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Tabs, Tab, Stack, AppBar } from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import GitHubIcon from "@mui/icons-material/GitHub";
import Box from "@mui/material/Box";

const TabbedNavigation = () => {
  const [value, setValue] = React.useState("/");

  const handleChange = (event, newValue) => {
    //console.log(newValue)
    if (newValue && isNaN(newValue) && !newValue.includes('diffmodel') && !newValue.includes('coraliris')) {
      setValue(newValue);
    }
  };

  const setSelectedTab = (val) => {
    if (val && isNaN(val) && !val.includes('diffmodel') && !val.includes('coraliris')) {
      setValue(val);
    }
  }

  const tabsGutter = 4;

  const location = useLocation()

  const imgStyle = {
    display: "block",
    height: "auto",
    margin: "auto",
    maxWidth: "100%",
  };

  useEffect(() => {
    //const parsedLoc = location.pathname.replace(/\W/g, " ").trim();
    setSelectedTab(location.pathname)
  }, [location]);

  return (
      <div>
        <AppBar position="relative" sx={{boxShadow: 'none', marginBottom: '40px'}}>
          <Stack direction="row" justifyContent="space-between">
          <Box sx={{display: "block", width: "262px", marginLeft: "10px"}}>
          <img src={`${process.env.PUBLIC_URL}/assets/images/nirvanalogo.svg`} alt={'Nirvana Choudhury'}  style={imgStyle}/>
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="fullWidth"
              sx={{ paddingTop: `${tabsGutter}px` }}
              TabIndicatorProps={{
                sx: {
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, .2)",
                },
              }}
            >
              <Tab
                sx={{ margin: `0 ${tabsGutter}px`, minWidth: '75px',  fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 600  }}
                label="Home"
                component={Link}
                to="/"
                value="/"
              />
              <Tab
                sx={{ margin: `0 ${tabsGutter}px`, minWidth: '75px',  fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 600 }}
                label="Projects"
                component={Link}
                to="/projects"
                value="/projects"
              />
              <Tab
                sx={{ margin: `0 ${tabsGutter}px`, minWidth: '75px',  fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 600 }}
                label="Books"
                component={Link}
                to="/books"
                value="/books"
              />
              <Tab
                sx={{ margin: `0 ${tabsGutter}px`, minWidth: '75px',  fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 600 }}
                label="Articles"
                component={Link}
                to="/articles"
                value="/articles"
              />
              <Tab
                sx={{ margin: `0 ${tabsGutter}px`, minWidth: '75px',  fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 600 }}
                label="About"
                component={Link}
                to="/about"
                value="/about"
              />
            </Tabs>
          </Stack>
          <Stack direction="row" justifyContent="right">
          <Box
              size="small"
              sx={{ marginLeft: "16px", color: "inherit", paddingTop: '8px' }}
              target="_blank" to="https://github.com/nirvanasupermind/tachyon"
              component={Link}
            >
              <YouTubeIcon sx={{ fontSize: "1.4rem", color:"#66747e" }} />
            </Box>
            <Box
              size="small"
              sx={{ marginLeft: "16px", color: "inherit", paddingTop: '8px' }}
              target="_blank" to="https://github.com/nirvanasupermind/tachyon"
              component={Link}
            >
              <GitHubIcon sx={{ fontSize: "1.4rem", color:"#66747e" }} />
            </Box>
            </Stack>
          </Stack>
        </AppBar>
      </div>
  );
};
export default TabbedNavigation;
