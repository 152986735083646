//import { Box, Stack, Typography } from "@mui/material";
import React from "react";

/* --color-neutral-muted: rgba(175,184,193,0.2);
  --color-neutral-subtle: rgba(234,238,242,0.5); */
  const blockWrapAlignFullStyle = {
    position: "relative",
    width: "calc(100vw - 17px)",
    maxWidth: "100vw !important",
    left: "50%",
    transform: "translateX(-50%)",
    padding: "0 30px" 
  };
const About = () => {
  return (
    <>
      <article
        itemScope=""
        itemType="https://schema.org/CreativeWork"
        style={{ display: "block" }}
      >
        <div
          itemProp="text"
          style={{ maxWidth: "650px", position: "relative", margin: "auto" }}
        >
          <div style={blockWrapAlignFullStyle}>
            <div style={{ padding: "56px 20px 0px" }}>
              <div
                style={{
                  position: "relative",
                  maxWidth: "1110px",
                  margin: "auto",
                }}
              >
                <h1
                  style={{
                    margin: "0 0 20px",
                    fontSize: "48px",
                    lineHeight: "66px",
                    letterSpacing: "-1px",
                  }}
                >
                  About Me
                </h1>
                <div style={{ maxWidth: "825px" }}>
                  <p
                    style={{
                      margin: "0 0 1em",
                      fontSize: "28px",
                      lineHeight: "37px",
                    }}
                  >
                    Hi There! My name is Nirvana.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <style>
            {`
              .container {
                position: relative;
                max-width: 1110px;
                margin: auto;
              }
              .block.text-and-image .container {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
              }
              .block.text-and-image .copy {
                max-width: 540px;
              }
              p {
                margin: 0 0 32px;
                font-size: 22px;
                line-height: 32px;
                font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
              }
              .block.text-and-image .image {
                max-width: 363px;
                margin: 0 40px;
                position: relative;
              }
            `}
          </style>
          <div className={"blockWrap"} style={blockWrapAlignFullStyle}>
            <div
              className={"block text-and-image"}
              style={{
                lineHeight: 1.15,
                WebkitTextSizeAdjust: "100%",
                fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                color: "#001828",
                WebkitFontSmoothing: "antialiased",
                boxSizing: "border-box",
                marginBottom: "103px",
              }}
            >
              <div className={"container"}>
                <div className={"copy"}>
                  <p>
                    Inspired by Steve Jobs and my own parents, I always wanted
                    to be a techpreneur ever since kindergarten. My parents
                    always told me that age was no barrier to innovation, so I
                    pursued my passions without fear. I focused on learning
                    whatever was needed along the way to manifest my ideas into
                    reality. I found that the most interesting insights lie at
                    the cusp of multiple disciplines.
                  </p>
                  <p>
                    My love for mathematics intersects with programming. My
                    passion is using computational mathematics to solve complex
                    problems across multiple domains. One of the cool ways I use
                    computational mathematics is in astronomy - to create
                    physics-based simulation for planets &amp; solar systems. I
                    also love exploring new architectures for AI models,
                    specifically generative deep models. I have an intense
                    interest in googology, number theory &amp; graph theory.
                  </p>
                  <p>
                    I find writing a very powerful and effective tool to convey
                    my ideas and imagination. I am a published Sci-fi author and
                    currently working on an upcoming book on Stable Diffusion.
                  </p>
                </div>
                <div className={"image"}>
                  <div
                    style={{
                      boxShadow: "0px 25px 60px rgba(17, 51, 75, 0.15)",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      zIndex: 1,
                      position: "absolute",
                      display: "block",
                      content: "",
                    }}
                  ></div>
                  <img
                    style={{
                      position: "relative",
                      zIndex: 3,
                      display: "block",
                      maxWidth: "100%",
                      height: "auto",
                      margin: "auto",
                    }}
                    width="900"
                    height="1160"
                    src={`${process.env.PUBLIC_URL}/assets/images/nirvana-profile-2.png`}
                    className={"attachment-full size-full"}
                    alt=""
                    sizes="(max-width: 900px) 100vw, 900px"
                  />{" "}
                  <div
                    style={{
                      right: "-74px",
                      bottom: "-65px",
                      width: "171px",
                      height: "151px",
                      background: `url(${process.env.PUBLIC_URL}/assets/images/brush-stroke-blue.svg) no-repeat center`,
                      backgroundSize: "contain",
                      position: "absolute",
                      display: "block",
                      content: "",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default About;
