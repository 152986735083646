//import { Box, Stack, Typography } from "@mui/material";
import React from "react";

/* --color-neutral-muted: rgba(175,184,193,0.2);
  --color-neutral-subtle: rgba(234,238,242,0.5); */

const CoralIris = () => {
  return (
    <>
      <style>
        {`
            body {
                background: #eef1f0 url(${process.env.PUBLIC_URL}/assets/images/books/diffmodel-background.jpg) repeat center 79px;
                font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
            }
            body {
                line-height: 1;
            }
            #page {
                width: 1150px;
                background: #fff;
                margin: 50px auto;
                -webkit-box-shadow: 0px 0px 5px rgba(0,0,0,.3);
                -moz-box-shadow: 0px 0px 5px rgba(0,0,0,.3);
                box-shadow: 0px 0px 5px rgba(0,0,0,.3);
                position: relative;
            }
            html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
                margin: 0;
                padding: 0;
                border: 0;
                outline: 0;
                font-size: 100%;
                vertical-align: baseline;
                background: transparent;
            }
        `}
      </style>
      <div id="page">
        <style>
          {`#page .heading {
                text-align: center;
                padding-top: 100px;
            }

            h1, h2 {
                margin: 10px 0;
                font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
                font-size: 42px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: #272727;
            }
          
          #page .content_lead {
            padding: 40px 100px;
            border-bottom: 1px solid #eee;
            margin-bottom: 50px;
        }
        p, li {
            font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
            font-size: 18px;
            /*font-size: 28px;*/
            font-style: normal;
            line-height: 1.5em;
            /*line-height: 37px;*/
            margin: 20px 0;
        }
        ul {
            padding-left: 45px;
        }
        `}
        </style>
        <h1 className="heading">Coral Iris</h1>

        <div className="content content_lead">
          <p>
          Coral reefs harbor 25% of marine life, yet 90% will die by 2050. Overfishing, pollution, and warming oceans are bleaching and killing corals.  Fishing stocks, tourism &amp; coastal protection would be devastated if corals die. Coral reefs provide $375 billion of economic value each year.  Early intervention is critical to saving marine lives and economies. However traditional methods miss early warning signs.  We need a mechanism that can rapidly scan vast reefs, enabling preventative action before irreversible damage.
          </p>

          <p>
          <img
                          width="960"
                          height="468"
                          src={`${process.env.PUBLIC_URL}/assets/images/projects/coral-iris-architecture.png`}
                          className="attachment-full size-full"
                          alt=""
                          sizes="(max-width: 960px) 100vw, 960px"
                        /></p>
          <p>Coral Iris is an AI-powered monitoring platform for early detection of coral health issues. It uses a predictive AI model trained to analyze underwater coral images. It will enable scientists to monitor coral health much more effectively. It will also empower citizens to contribute to reef monitoring by uploading images. By providing a collaborative framework for marine biologists, citizen scientists &amp; government agencies, Coral Iris can play a game-changing role in early intervention strategies to save coral reefs.</p>

          <ul>
            <li>Early detection: By detecting coral bleaching or disease, before they advance too far, AI can enable scientists to intervene before the situation becomes critical.</li>
            <li>Accuracy and objectivity: Trained on vast datasets, AI models can identify subtle changes in coral health invisible to human observers. This leads to more accurate and objective assessments of coral health.</li>
            <li>Speed and scale: Manually assessing coral health is time-consuming and expensive. AI can analyze images much faster and at a larger scale, providing a wider spatial assessment of reef health.</li>
            <li>Monitoring and tracking: AI can continuously monitor coral reef health over time, providing valuable data to flag changes and measure impact of conservation efforts.</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CoralIris;
