//import { Box, Stack, Typography } from "@mui/material";
import React from "react";

/* --color-neutral-muted: rgba(175,184,193,0.2);
  --color-neutral-subtle: rgba(234,238,242,0.5); */
  const blockWrapAlignFullStyle = {
    position: "relative",
    width: "calc(100vw - 17px)",
    maxWidth: "100vw !important",
    left: "50%",
    transform: "translateX(-50%)",
    padding: "0 30px"
  };
const Books = () => {
  return (
    <>
      <article
        itemScope=""
        itemType="https://schema.org/CreativeWork"
        style={{ display: "block" }}
      >
        <div
          itemProp="text"
          style={{ maxWidth: "650px", position: "relative", margin: "auto" }}
        >
          <div style={blockWrapAlignFullStyle}>
            <div style={{ padding: "56px 0 53px" }}>
              <div
                style={{
                  position: "relative",
                  maxWidth: "1110px",
                  margin: "auto",
                }}
              >
                <h1
                  style={{
                    margin: "0 0 20px",
                    fontSize: "48px",
                    lineHeight: "66px",
                    letterSpacing: "-1px",
                  }}
                >
                  Books
                </h1>
                <div style={{ maxWidth: "825px" }}>
                  <p
                    style={{
                      margin: "0 0 1em",
                      fontSize: "28px",
                      lineHeight: "37px",
                    }}
                  >
                    My goal for writing books on specific topics of AI is to
                    provide clear, concise &amp; practical information on the
                    subject matter so that AI enthusiasts can learn about the
                    mathematical fundamentals of this exciting technology, but
                    also can learn how to implement them using Python.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <style>
            {`
              .container.padding {
                position: relative;
                max-width: 1170px;
                padding: 0 30px;
                margin: auto;
              }
              .row {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 81px;
              }
              .block.book .row {
                margin-bottom: 0;
              }
              .block.book.orange-background .row {
                align-items: center;
              }
              .block.book .cover {
                position: relative;
                max-width: 350px;
                margin-right: 40px;
              }
              .block.book.orange-background .cover {
                align-self: flex-start;
              }
              .block.book .cover::before {
                position: absolute;
                display: block;
                content: '';
                box-shadow: 0px 20px 45px rgba(17, 51, 75, 0.15);
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
              }
              .row .content {
                max-width: 635px;
              }
              .block.book .content {
                position: relative;
                padding-top: 57px;
              }
              .block.book.orange-background .content {
                padding-top: 0;
              }
              .row .content h2 {
                font-size: 20px;
                font-weight: normal;
                line-height: normal;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin: 0 0 23px;
              }
              .block.book .content h2 {
                margin-bottom: 7px;
                color: #f26111;
              }
              .block.book.orange-background .content h2 {
                margin-bottom: 3px;
              }
              .block.book .content h3 {
                margin: 0 0 38px;
                font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
                font-size: 78px;
                font-weight: bold;
                line-height: 82px;
                color: #001828;
                text-shadow: 2px 2px #fff, 2px -2px #fff, -2px 2px #fff, -2px -2px #fff, 3px 3px #fff, 3px -3px #fff, -3px 3px #fff, -3px -3px #fff;
                text-transform: none;
                letter-spacing: -1.5px;
              }
              .block.book.orange-background .content h3 {
                margin-bottom: 22px;
                font-size: 48px;
                line-height: 66px;
                text-shadow: none;
                letter-spacing: -1px;
              }
              .block a:not(.underline):not(.btn) {
                font-weight: inherit;
                color: inherit;
                border-bottom: none;
              }
              .block img {
                display: block;
                max-width: 100%;
                height: auto;
                margin: auto;
              }
              .block.book .cover img {
                position: relative;
                z-index: 3;
              }
              .block.book .cover > a img:hover, .block.book .cover > a img:focus {
                box-shadow: 0px 20px 45px rgba(17, 51, 75, 0.25);
                transform: translateY(-4px);
              }
              .row .content p {
                margin: 0 0 36px;
                font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
                font-size: 28px;
                line-height: 37px;
              }
              .block.book .content p {
                margin: 0 0 37px;
                font-size: 20px;
                line-height: 29px;
              }
              .block.book.orange-background .content p {
                margin-bottom: 30px;
                font-size: 20px;
                line-height: 29px;
              }
              .block.product .links {
                display: flex;
                flex-wrap: wrap;
                margin: -10px -25px;
              }
              .block.product .links .item {
                margin: 10px 25px;
              }
            `}
          </style>
          <div className={"blockWrap"} style={blockWrapAlignFullStyle}>
            <div
              className={"block book orange-background"}
              style={{
                lineHeight: 1.15,
                WebkitTextSizeAdjust: "100%",
                fontFamily:
                  "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                color: "#001828",
                WebkitFontSmoothing: "antialiased",
                boxSizing: "border-box",
                padding: "96px 0",
                marginBottom: "30px",
                backgroundColor: "#fef7f3",
              }}
            >
              <div className={"container padding"}>
                <div className={"row"}>
                  <div className={"cover"}>
                    <a href="//nirvanaself.com/diffmodel/" target="">
                      <span>
                        <img
                          width="600"
                          height="838"
                          src={`${process.env.PUBLIC_URL}/assets/images/books/diffmodel-cover-1.png`}
                          className="attachment-full size-full"
                          alt=""
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </span>
                    </a>
                  </div>
                  <div className={"content"}>
                    <h2>Book</h2>
                    <h3>
                      <a href="//nirvanaself.com/diffmodel/" target="">
                        <span>Understanding Diffusion Models</span>
                      </a>
                    </h3>
                    <p>
                      This is a book I am currently authoring on fundamentals of
                      diffusion models. They are types of generative models,
                      which learn the underlying distribution of training data
                      for data generation by progressively adding and removing
                      noise.
                    </p>
                    <div className={"links"}>
                      <div className={"item button"}>
                        <a
                          className={"btn btn-primary"}
                          href="//nirvanaself.com/diffmodel/"
                          target=""
                        >
                          <span>Learn More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default Books;
